import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import moment from 'moment'
import { getLocalStorageInfo, showCurrencyFormat } from '../../../../utils'
import { checkPermission } from '../../../../utils_v2'
import { displayDateFormat, fieldDateFormat, backendDateFormat } from '../../../../constants'
import InvFields from './InvFields'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NumericLabel from 'react-pretty-numbers';
import { FieldCol, FieldLabel, FieldGroup, FieldInput } from '../../Elements';
import { Tooltip } from '@material-ui/core'
import { History } from '@material-ui/icons'
export default class InvoiceInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: {}
    }
  }

  render() {
    const { invoiceInfo, invoiceStatusList, type, startDate, endDate, contractInfo,error } = this.props
    // const { error } = this.state
    let userInfo= getLocalStorageInfo()
    return (
      <Row className="margin-0">
        {
          type === 'edit' ?
            <Col md="3">
              <div className="form-group">
                <label className="label">Invoice Number </label>
                {
                  invoiceInfo.status == 1 ?
                    <input type="text" onFocus={() => this.validationClear('invoice_number')} value={invoiceInfo.invoice_number} onChange={(e) => this.props.updateInvoiceField(e.target.value, 'invoice_number')} /> :
                    <h6 className="theta">{invoiceInfo.invoice_number}</h6>
                }
                <h6 className="error-msg">{error.invoice_number}</h6>

              </div>
            </Col>
            : <InvFields type="text" label="Invoice Number " colSize="3" value={invoiceInfo.invoice_number} />
        }
        <Col md="3">
          <div className="form-group">
            <label className="label" style={{display:'flex'}}>Invoice Status
              <span onClick={this.props.viewTimeline} style={{cursor:'pointer', marginLeft:'4px'}}>
                <Tooltip title='Invoice Status timeline' >
                  <History fontSize="small" color='primary' />
                </Tooltip>
              </span>
            </label>
            { type === 'edit' ?
              <h6 className="theta">{invoiceStatusList[invoiceInfo.status]}</h6> : 
              <h6 className="para-ui">
                  {invoiceStatusList[invoiceInfo.status]}
                  { checkPermission('contracts','invoices','U') && contractInfo?.hdlineInfo?.archived_asset === false && [1,2,5].includes(invoiceInfo.status) && invoiceInfo.summary_invoice_enabled === false && invoiceInfo.qb_invoice===null ?
                    <span style={{ fontSize: '12px', color: '#3f51b5', paddingLeft: '10px', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => this.props.toggleInvoiceState()}>Change Status</span> : null
                  }
                  { checkPermission('contracts','invoices','STS') && contractInfo?.hdlineInfo?.archived_asset === false && [3].includes(invoiceInfo.status)&& invoiceInfo.summary_invoice_enabled === false && invoiceInfo.qb_invoice===null ?
                    <span style={{ fontSize: '12px', color: '#3f51b5', paddingLeft: '10px', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => this.props.toggleInvoiceState()}>Change Status</span> : null
                  }
                </h6>
            }
            {
              type === 'edit' ?
                <h6 className="error-msg">{error?.invoice_number}</h6>
                : null
            }
            { type === 'edit' ? <h6 className="error-msg">{error.invoice_number}</h6> : null }
          </div>
        </Col>
        {
          invoiceInfo.status == 5 ?
            type == "edit" ?
              <FieldCol md="3">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                    Partial Paid Amount
                  </FieldLabel>
                  <input
                    value={invoiceInfo.partial_paid_amount}
                    type="text"
                    onChange={(e) => this.props.updateInvoiceField(e.target.value, 'partial_paid_amount')}
                  />
                  <h6 className="error-msg">{this.props.error.partial_paid_amount}</h6>
                </FieldGroup>
              </FieldCol>
              :
              <InvFields type="text" label="Partial Paid Amount" colSize="3" value={<NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>
                {invoiceInfo.partial_paid_amount}
              </NumericLabel>} /> : null
        }
        {
          invoiceInfo.status == 5 ?
            type == "edit" ?
              <FieldCol md="3">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Partial Payment Date</FieldLabel>
                  <DatePicker
                    dateFormat={fieldDateFormat}
                    selected={invoiceInfo.partial_paid_date && moment(invoiceInfo.partial_paid_date).isValid() ? moment(invoiceInfo.partial_paid_date) : null}
                    onChange={(e) => this.props.updateInvoiceField(moment(e).format(backendDateFormat), 'partial_paid_date')}
                    onFocus={() => this.props.validationClear('partial_paid_date')}
                    minDate={moment(startDate)}
                    maxDate={moment(endDate)}
                    showYearDropdown
                    showMonthDropdown
                    useShortMonthInDropdown
                  />
                </FieldGroup>
              </FieldCol> :
              <InvFields type="text" label="Partial Payment Date" colSize="3" value={invoiceInfo.partial_paid_date ? moment(invoiceInfo.partial_paid_date).format(displayDateFormat) : '--'} />
            : null
        }
        {
          type === 'edit' ?
            <Col md="3">
              <div className="form-group">
                <label className="label">Invoice Date</label>
                <DatePicker
                  dateFormat={fieldDateFormat}
                  selected={invoiceInfo.date && moment(invoiceInfo.date).isValid() ? moment(invoiceInfo.date) : null}
                  onChange={(e) => this.props.updateInvoiceField(moment(e).format(backendDateFormat), 'date')}
                  onFocus={() => this.props.validationClear('date')}
                  minDate={moment(invoiceInfo.contract_start_date)}
                  maxDate={moment(invoiceInfo.contract_end_date).subtract(15, 'days')}
                  showYearDropdown
                  showMonthDropdown
                  useShortMonthInDropdown
                />
                <h6 className="error-msg">{error?.date}</h6>
              </div>
            </Col>
            : <InvFields label="Invoice Date " colSize="3" value={invoiceInfo.date ? moment(invoiceInfo.date).format(displayDateFormat) : '--'} />
        }
        {
          type === 'edit' ?
            <Col md="3">
              <div className="form-group">
                <label className="label">Invoice Due Date {type === 'edit' ? <sup className="reqStar"> * </sup> : null}</label>
                <DatePicker
                  dateFormat={fieldDateFormat}
                  selected={invoiceInfo.due_date && moment(invoiceInfo.due_date).isValid() ? moment(invoiceInfo.due_date) : null}
                  onChange={(e) => this.props.updateInvoiceField(moment(e).format(backendDateFormat), 'due_date')}
                  onFocus={() => this.props.validationClear('due_date')}
                  showYearDropdown
                  showMonthDropdown
                  useShortMonthInDropdown
                  minDate={moment(invoiceInfo.date)}
                  maxDate={moment(invoiceInfo.contract_end_date).subtract(15, 'days')}
                />
                <h6 className="error-msg">{error?.due_date}</h6>
              </div>
            </Col>
            : <InvFields label="Invoice Due Date " colSize="3" value={invoiceInfo.due_date ? moment(invoiceInfo.due_date).format(displayDateFormat) : '--'} />
        }
        {
          type === 'edit' ?
            <Col md="3">
              <div className="form-group">
                <label className="label">{(invoiceInfo.invoice_type == '7' || invoiceInfo.invoice_type == '8') ? 'Policy' : invoiceInfo.invoice_type == '1' ? 'Utilization Period' : invoiceInfo.invoice_type == '3' || invoiceInfo.invoice_type == '4' || invoiceInfo.invoice_type == '5' ? invoiceInfo.parent_invoice_type == 1 ? 'Utilization Period' : 'Rental Period' : 'Rental Period'} Start Date</label>
                {invoiceInfo.invoice_type == '1' ?
                  <DatePicker
                    dateFormat={fieldDateFormat}
                    selected={invoiceInfo.start_date && moment(invoiceInfo.start_date).isValid() ? moment(invoiceInfo.start_date) : null}
                    onChange={(e) => this.props.updateInvoiceField(moment(e).format(backendDateFormat), 'start_date')}
                    minDate={moment(moment(invoiceInfo.start_date).format('YYYY') + '-' + moment(invoiceInfo.start_date).format('MM') + '-01')}
                    maxDate={moment(moment(invoiceInfo.start_date).format('YYYY') + '-' + moment(invoiceInfo.start_date).format('MM') + '-01').add(1, 'months').subtract(1, 'days')}
                    onFocus={() => this.props.validationClear('start_date')}
                    showYearDropdown
                    showMonthDropdown
                    useShortMonthInDropdown
                  /> :
                  invoiceInfo.invoice_type == '9' ?
                    <DatePicker
                      dateFormat={fieldDateFormat}
                      selected={invoiceInfo.start_date && moment(invoiceInfo.start_date).isValid() ? moment(invoiceInfo.start_date) : null}
                      onChange={(e) => this.props.updateInvoiceField(moment(e).format(backendDateFormat), 'start_date')}
                      minDate={moment(invoiceInfo.parent_invoice_start_date)}
                      maxDate={moment(invoiceInfo.parent_invoice_end_date)}
                      onFocus={() => this.props.validationClear('start_date')}
                      showYearDropdown
                      showMonthDropdown
                      useShortMonthInDropdown
                    />
                    : invoiceInfo.invoice_type === 2 && invoiceInfo.interval === 5 ?
                      <DatePicker
                        dateFormat={fieldDateFormat}
                        selected={invoiceInfo.start_date && moment(invoiceInfo.start_date).isValid() ? moment(invoiceInfo.start_date) : null}
                        onChange={(e) => this.props.updateInvoiceField(moment(e).format(backendDateFormat), 'start_date')}
                        minDate={moment(invoiceInfo.rental_start_date)}
                        maxDate={moment(invoiceInfo.end_date)}
                        onFocus={() => this.props.validationClear('start_date')}
                        showYearDropdown
                        showMonthDropdown
                        useShortMonthInDropdown
                      /> : invoiceInfo.invoice_type == 2 ?
                        <DatePicker
                          dateFormat={fieldDateFormat}
                          selected={invoiceInfo.start_date && moment(invoiceInfo.start_date).isValid() ? moment(invoiceInfo.start_date) : null}
                          onChange={(e) => this.props.updateInvoiceField(moment(e).format(backendDateFormat), 'start_date')}
                          minDate={moment(invoiceInfo.rental_start_date)}
                          maxDate={moment(invoiceInfo.end_date)}
                          onFocus={() => this.props.validationClear('start_date')}
                          showYearDropdown
                          showMonthDropdown
                          useShortMonthInDropdown
                        /> :
                        <DatePicker
                          dateFormat={fieldDateFormat}
                          selected={invoiceInfo.start_date && moment(invoiceInfo.start_date).isValid() ? moment(invoiceInfo.start_date) : null}
                          onChange={(e) => this.props.updateInvoiceField(moment(e).format(backendDateFormat), 'start_date')}
                          minDate={moment(invoiceInfo.start_date)}
                          maxDate={moment(invoiceInfo.start_date)}
                          onFocus={() => this.props.validationClear('start_date')}
                          showYearDropdown
                          showMonthDropdown
                          useShortMonthInDropdown
                        />
                }
                <h6 className="error-msg">{error.start_date}</h6>
              </div>
            </Col>
            : <InvFields
              label={(invoiceInfo.invoice_type == '7' || invoiceInfo.invoice_type == '8') ? 'Policy Start Date' : invoiceInfo.invoice_type == '1' ?
                'Utilization Period Start Date' : invoiceInfo.invoice_type == '3' || invoiceInfo.invoice_type == '4' || invoiceInfo.invoice_type == '5' ?
                  invoiceInfo.parent_invoice_type == 1 ?
                    'Utilization Period Start Date' : 'Rental Period Start Date' : 'Rental Period Start Date'
              }
              colSize="3" value={invoiceInfo.start_date ? moment(invoiceInfo.start_date).format(displayDateFormat) : '--'} />
        }
        {
          type === 'edit' ?
            <Col md="3">
              <div className="form-group">
                <label className="label">{(invoiceInfo.invoice_type == '7' || invoiceInfo.invoice_type == '8') ? 'Policy' : invoiceInfo.invoice_type == '1' ? 'Utilization Period' : invoiceInfo.invoice_type == '3' || invoiceInfo.invoice_type == '4' || invoiceInfo.invoice_type == '5' ? invoiceInfo.parent_invoice_type == 1 ? 'Utilization Period' : 'Rental Period' : 'Rental Period'} End Date</label>
                {invoiceInfo.invoice_type == '1' ?
                  <DatePicker
                    dateFormat={fieldDateFormat}
                    selected={invoiceInfo.end_date && moment(invoiceInfo.end_date).isValid() ? moment(invoiceInfo.end_date) : null}
                    onChange={(e) => this.props.updateInvoiceField(moment(e).format(backendDateFormat), 'end_date')}
                    minDate={moment(moment(invoiceInfo.start_date).format('YYYY') + '-' + moment(invoiceInfo.start_date).format('MM') + '-01')}
                    maxDate={moment(moment(invoiceInfo.start_date).format('YYYY') + '-' + moment(invoiceInfo.start_date).format('MM')).add(2, 'months').subtract(1, "days")}
                    onFocus={() => this.props.validationClear('end_date')}
                    showYearDropdown
                    showMonthDropdown
                    useShortMonthInDropdown
                  /> :
                  invoiceInfo.invoice_type == '9' ?
                    <DatePicker
                      dateFormat={fieldDateFormat}
                      selected={invoiceInfo.end_date && moment(invoiceInfo.end_date).isValid() ? moment(invoiceInfo.end_date) : null}
                      onChange={(e) => this.props.updateInvoiceField(moment(e).format(backendDateFormat), 'end_date')}
                      minDate={moment(invoiceInfo.start_date)}
                      maxDate={moment(invoiceInfo.parent_invoice_end_date)}
                      onFocus={() => this.props.validationClear('end_date')}
                      showYearDropdown
                      showMonthDropdown
                      useShortMonthInDropdown
                    /> :
                    invoiceInfo.invoice_type == '2' && invoiceInfo.interval === 5 ?
                      <DatePicker
                        dateFormat={fieldDateFormat}
                        selected={invoiceInfo.end_date && moment(invoiceInfo.end_date).isValid() ? moment(invoiceInfo.end_date) : null}
                        onChange={(e) => this.props.updateInvoiceField(moment(e).format(backendDateFormat), 'end_date')}
                        minDate={moment(invoiceInfo.start_date)}
                        maxDate={moment(invoiceInfo.rental_end_date)}
                        onFocus={() => this.props.validationClear('end_date')}
                        showYearDropdown
                        showMonthDropdown
                        useShortMonthInDropdown
                      /> : invoiceInfo.invoice_type == '2' ?
                        <DatePicker
                          dateFormat={fieldDateFormat}
                          selected={invoiceInfo.end_date && moment(invoiceInfo.end_date).isValid() ? moment(invoiceInfo.end_date) : null}
                          onChange={(e) => this.props.updateInvoiceField(moment(e).format(backendDateFormat), 'end_date')}
                          minDate={moment(invoiceInfo.start_date)}
                          maxDate={moment(invoiceInfo.rental_end_date)}
                          onFocus={() => this.props.validationClear('end_date')}
                          showYearDropdown
                          showMonthDropdown
                          useShortMonthInDropdown
                        />
                        : <DatePicker
                          dateFormat={fieldDateFormat}
                          selected={invoiceInfo.end_date && moment(invoiceInfo.end_date).isValid() ? moment(invoiceInfo.end_date) : null}
                          onChange={(e) => this.props.updateInvoiceField(moment(e).format(backendDateFormat), 'end_date')}
                          minDate={moment(moment(invoiceInfo.start_date).format('YYYY') + '-' + moment(invoiceInfo.start_date).format('MM'))}
                          maxDate={moment(moment(invoiceInfo.start_date).format('YYYY') + '-' + moment(invoiceInfo.start_date).format('MM')).add(2, 'months').subtract(1, "days")}
                          onFocus={() => this.props.validationClear('end_date')}
                          showYearDropdown
                          showMonthDropdown
                          useShortMonthInDropdown
                        />
                }
                <h6 className="error-msg">{error.end_date}</h6>

              </div>
            </Col>
            : <InvFields label={(invoiceInfo.invoice_type == '7' || invoiceInfo.invoice_type == '8') ? 'Policy End Date' : invoiceInfo.invoice_type == '1' ? 'Utilization Period End Date' : invoiceInfo.invoice_type == '3' || invoiceInfo.invoice_type == '4' || invoiceInfo.invoice_type == '5' ? invoiceInfo.parent_invoice_type == 1 ? 'Utilization Period End Date' : 'Rental Period End Date' : 'Rental Period End Date'} colSize="3" value={invoiceInfo.end_date ? moment(invoiceInfo.end_date).format(displayDateFormat) : '--'} />
        }
        {
          invoiceInfo.invoice_type == '7' || invoiceInfo.invoice_type == '8' ?
            <InvFields colSize="3" label="Insurance Type" value={invoiceInfo.insurance_type && invoiceInfo.insurance_type.label ? invoiceInfo.insurance_type.label : '--'} />
            : null
        }
        {
          invoiceInfo.invoice_type == '7' || invoiceInfo.invoice_type == '8' ?
            <InvFields colSize="3" label="Insurance Number" value={invoiceInfo.insurance_number ? invoiceInfo.insurance_number : '--'} />
            : null
        }
        {
          invoiceInfo.invoice_type == '7' || invoiceInfo.invoice_type == '8' ?
            <InvFields colSize="3" label="Insurer" value={invoiceInfo.insurer ? invoiceInfo.insurer : '--'} />
            : null
        }
        {
          invoiceInfo.invoice_type == '7' || invoiceInfo.invoice_type == '8' ?
            <InvFields colSize="3" label="Insuree" value={invoiceInfo.insuree ? invoiceInfo.insuree : '--'} />
            : null
        }
        {
          invoiceInfo.invoice_type == '8' ?
            <InvFields colSize="3" label="Claim Details" value={invoiceInfo.claim_summary ? invoiceInfo.claim_summary : '--'} />
            : null
        }
        {
          invoiceInfo.invoice_type == '7' ?
            <InvFields colSize="3" label="Effective Date" value={invoiceInfo.effective_date ? invoiceInfo.effective_date : '--'} />
            : null
        }
        {
          type === 'edit' ?
            invoiceInfo.invoice_type == '3' ?
              <Col md="3">
                <div className="form-group">
                  <label className="label">6 Months USD LIBOR as of </label>

                  <DatePicker
                    dateFormat={fieldDateFormat}
                    selected={invoiceInfo.end_date && moment(invoiceInfo.libor_date).isValid() ? moment(invoiceInfo.libor_date) : moment()}
                    onChange={(e) => this.props.updateInvoiceField(moment(e).format(backendDateFormat), 'libor_date')}
                    showYearDropdown
                    showMonthDropdown
                    useShortMonthInDropdown
                  />
                </div>
              </Col> : null
            : null
        }
        {invoiceInfo.status == 3 && invoiceInfo.paid_date && type === 'view' ?
          <InvFields label="Invoice Payment Date " colSize="3" value={invoiceInfo.paid_date ? moment(invoiceInfo.paid_date).format(displayDateFormat) : '--'} /> : null
        }
      </Row>
    )
  }
}
