import React, { Component } from 'react'
import { connect } from 'react-redux';
import { sendCredsFromDb, sendCredsToDb } from '../apiServices';
import { Paper } from '@material-ui/core';
import { browserHistory } from 'react-router';
import { withSnackbar } from 'notistack';
import checkApiStatus from '../../../../utils';
import { ListLoader } from '../../../../shared';
import { getLocalStorageInfo } from '../../../../utils_v2';
const style = {
    homePage: {
        marginLeft: "60px"
    }
}
class ThirdPartyLanding extends Component {
    constructor(props) {
        super(props)
        this.state = {
            remaining: null,
            pageLoader: false
        }
        this.sendCredsToDb = sendCredsToDb.bind(this)
        this.sendCredsFromDb = sendCredsFromDb.bind(this)
    }
    componentDidMount() {
        const syncState = localStorage.getItem('thirdPartyState')
        let code = window.location?.search.split('code=')[1].split('&')[0]
        let realmId = window.location?.search.split('realmId=')[1]
        let synState = (syncState && syncState === 'SyncFromQB') ? (Math.floor(Math.random() * 9000) + 1000): window.location?.search.split('state=')[1].split('&')[0]
        if (code == 'error') {
            console.log('');
        }
        else {
            this.setState({ pageLoader: true })
            const apiCall = syncState === 'SyncFromQB' ? sendCredsFromDb : sendCredsToDb;

            apiCall({ auth_code: code, realm_id: realmId, state: synState })
                .then((response) => {
                    this.setState({ pageLoader: false })
                    if (checkApiStatus(response)) {
                        var i = 5
                        this.setState({ remaining: i })
                        localStorage.removeItem('thirdPartyState');
                        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                    } else {
                        var i = 10
                        this.setState({ remaining: i })
                        localStorage.removeItem('thirdPartyState');
                    }
                    setInterval(() => {
                        i--;
                        this.setState({ remaining: i })
                    }, 1000);
                    setTimeout(() => {
                        window.open('', '_self', '');
                        window.close()
                    }, checkApiStatus(response) ? 5000 : 10000)
                    if (window.opener && window?.opener.location) {
                        window.opener.location.reload()
                    }
                })
        }
    }
    render() {
        let code = window.location?.search?.split('code=')[1].split('&')[0];
        return (
            <div style={style.homePage}>
                {this.state?.pageLoader ? <ListLoader /> : null}
                <Paper>
                    <div style={{ height: '92vh', textAlign: 'center' }}>
                        {
                            code == 'error' ?
                                <div style={{ fontSize: '40px' }} > Oops, Something went wrong from QuickBooks side.</div>
                                :
                                <div style={{ fontSize: '40px' }} >
                                    Authentication successful with QuickBooks
                                </div>
                        }
                        <div>
                            {
                                code == 'error' ?
                                    <span>Please contact with Sparta team. </span> : null
                            }
                            {
                                this.state?.remaining === null ? null :
                                    `This tab will close in ${this.state.remaining} seconds.`
                            }
                        </div>
                    </div>
                </Paper>
            </div>
        )
    }
}

export default withSnackbar(ThirdPartyLanding);